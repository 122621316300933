@import "../../global-styles/settings/colors";

body {
  margin: 0;
  padding: 0;
}
.Button {
  border-radius: 4px;
}

#main-logo {
  top: 15px;
  left: 10px;
}
img {
  height: 40px;
  margin: 10px 20px 0 0;
}

.wrapper {
  display: flex;
  align-items: start;
  border-bottom: 1px solid $border;
}

.Header {
  position: relative;
  padding: 10px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hamburger {
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  margin: 10px;
}

.nav-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: none; /* Initially hidden */
  position: absolute; /* Prevent pushing content down */
  top: 10px;
  right: 40px;
  background-color: $primary;
  border-radius: 4px;
  z-index: 1000;
}

.nav-links li {
  padding: 15px;
}

.nav-links li a {
  color: white;
  text-decoration: none;
  display: block;
}

/* Show menu when active */
.nav-active {
  display: flex;
  flex-direction: column;
}

/* For larger screens, show the menu inline and hide the hamburger */
@media (min-width: 768px) {
  .nav-links {
    margin: 10px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    position: static;
    width: auto;
  }

  .hamburger {
    display: none;
  }
}
//ul {
//  display: flex;
  //.Button {
  //  &:not(:first-child) {
  //    border-top-left-radius: 0;
  //    border-bottom-left-radius: 0;
  //  }
  //  &:not(:last-child) {
  //    border-top-right-radius: 0;
  //    border-bottom-right-radius: 0;
  //  }
  //}
//}
