@import "../../global-styles/settings/colors";

.overview-table {
  table-layout: fixed;
  position: relative;

  .caretMobile {
    height: 18px;
    width: 18px;
  }
  &__pointer {
    cursor: pointer;
  }

  .ten {
    width: 10%;
  }
  .twenty {
    width: 20%;
  }
  .thirty {
    width: 30%;
  }

  .Input {
    margin-bottom: 10px;
    box-sizing: border-box;
  }

  .loading-icon {
    position: absolute;
    left: -24px
  }

  &__loading {
    color: rgba(black, 0.2);
    pointer-events: none;
  }

  &__order-caret {
    pointer-events: none;
    position: absolute;
  }

  &__page-selection {
    display: flex;
    width: 100%;
    margin-top: 8px;
    justify-content: flex-end;
    align-items: center;

    &__selectors {
      min-width: 120px;
      display: flex;
      justify-content: flex-end;

      svg {
        height: 100%;
      }
    }
  }

  &__previous:hover,
  &__next:hover {
    fill: $primary;
  }

  &__previous {
    margin-right: 24px;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiFormLabel-root.Mui-focused {
    color: $primary;
    border-color: $primary;
  }

  .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline {
    color: $primary;
    border-color: $primary;
  }
  input.MuiInputBase-input {
    padding: 16px;
    border: 0;
  }
}


