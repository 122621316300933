@import 'settings/colors';

table {
  width: 100%;
  line-height: 24px;
  border-collapse: collapse;
  td,
  th {
    padding: 4px;
    text-align: left;
  }
  th {
    background-color: white;
    color: #333;//#103B9F;
    font-weight: bold;
  }
  td {
    color: #555;
  }
  tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.03);
  }
  tr.OnClick {
    cursor: pointer;
    &:hover {
      background-color: transparentize($primary, 0.8);
    }
  }
}
