@import '../../../global-styles/settings/colors';

.Klant {
  position: relative;
  &.Error {
    color: $warning;
  }
  .noPadding {
    padding: 0;
  }
}
