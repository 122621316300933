@import 'src/global-styles/settings/colors';

.KlantKlant {
  positFietsOverview: relative;
  &.Error {
    color: $warning;
  }

  .capitalize {
    text-transform: capitalize;
  }

}
