@import '../../../global-styles/settings/colors';

.Arbeid {
  position: relative;
  &.Error {
    color: $warning;
  }

  th.prijs, td.prijs {
    text-align: right;
    max-height: 30px;
  }

  .EditMode {
    background-color: rgba(52, 107, 235, 0.1);
  }
}
.alignCenter .MuiInputBase-input {
  text-align: center;
}
.alignCenter {
  text-align: center;
}
