@import url('https://fonts.googleapis.com/css?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url("https://fonts.googleapis.com/css?family=Poppins:300,700|Roboto&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@font-face {
  font-family: 'Calibri';
  src: local('Calibri'), url(./fonts/Calibri.ttf) format('truetype');
}
body {
  padding: 0;
  font-size: 18px;
  font-family: 'Calibri', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code, .Mono {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
