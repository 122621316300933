@import "../../global-styles/settings/colors";

.agenda-select {
  //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  &__pointer {
    cursor: pointer;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .zindex {
    position: relative;
    z-index: 10;
    background-color: white;
  }

  .loading-icon {
    position: absolute;
    left: -24px
  }

  &__loading {
    color: rgba(black, 0.2);
    pointer-events: none;
  }

  &__order-caret {
    pointer-events: none;
    position: absolute;
  }

  &__page-selection {
    display: flex;
    width: 100%;
    margin-top: 8px;
    justify-content: flex-end;
    align-items: center;

    &__selectors {
      min-width: 120px;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__previous:hover,
  &__next:hover {
    fill: $primary;
  }

  &__previous {
    margin-right: 24px;
  }
}

.agenda {
  overflow-y: scroll;
  height: 300px;
  font-size: 14px;
}

