@import "global-styles/settings/colors";
@import "global-styles/table.scss";
@import "global-styles/title.scss";

.App {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;

  .Main {
    padding: 20px 0;
  }

  .ten {
    width: 10%;
  }

  .fifteen {
    width: 15%;
  }

  .twenty {
    width: 20%;
  }

  .twentyfive {
    width: 25%;
  }

  .thirty {
    width: 30%;
  }

  .forty {
    width: 40%;
  }

  .fifty {
    width: 50%;
  }
}

.svg-icon {
  width: 14px;
}

svg.loading-icon {
  fill: $primary;
  animation: spin 1s linear infinite;

  &__big {
    height: 42px;
    width: 42px;
  }
}

.caret-left {
  transform: rotate(180deg);
}

.caret-up {
  transform: rotate(270deg);
}

.caret-down {
  transform: rotate(90deg);
}

button {
  border: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

table td, table td * {
  vertical-align: top;
}

div.MuiInputBase-root,
input.MuiInputBase-input {
  padding: 1px;
  border: 0;
}
.w-20 {
  width: 20px;
}
.w-60 {
  width: 60px;
}
.h1Mobile {
  margin-top: 0;
  margin-bottom: 0;
  font-size: x-large;
}
.mobile {
  font-size: small;
}

