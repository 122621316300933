@import '../../../global-styles/settings/colors';

.Agenda {
  position: relative;
  &.Error {
    color: $warning;
  }
  .alignLeft, th.datum, td.datum {
    text-align: left;
  }

  th, td {
    text-align: center;
  }
  .EditMode {
    background-color: rgba(52, 107, 235, 0.1);
  }


  //.OnClick {
  //  cursor: pointer;
  //
  //  &:hover {
  //    background-color: transparentize($primary, 0.8);
  //  }
  //}

}
