@import "../../global-styles/settings/colors";

.search-select {
  //box-shadow: 10px 10px 5px grey;
  //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  //border: 1px solid grey;

  &__pointer {
    cursor: pointer;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .zindex {
    position: relative;
    //z-index: 10;
    background-color: white;
  }

  input.MuiInput-input {
    width: 100%;
    text-align: left;
    //  margin-bottom: 10px;
    //  box-sizing: border-box;
  }

  //
  .loading-icon {
    position: absolute;
    left: -24px
  }

  &__loading {
    color: rgba(black, 0.2);
    pointer-events: none;
  }

  &__order-caret {
    pointer-events: none;
    position: absolute;
  }

  &__page-selection {
    display: flex;
    width: 100%;
    margin-top: 8px;
    justify-content: flex-end;
    align-items: center;

    &__selectors {
      min-width: 120px;
      display: flex;
      justify-content: flex-end;

      svg {
        height: 100%;
      }
    }
  }

  &__previous:hover,
  &__next:hover {
    fill: $primary;
  }

  &__previous {
    margin-right: 24px;
  }


  .selectable-item {
    display: contents;
    width: 100%;
    position: relative;
    overflow: auto;
    max-height: 200px;
  }

  .textfieldmargin textarea {
    padding: 5px 0 4px;
  }
}