@import 'src/global-styles/settings/colors';

.KlantFiets {
  position: relative;
  &.Error {
    color: $warning;
  }

  input.MuiInput-input {
    text-align: left;
  }
}
.alignRight {
  text-align: right;
}
.bg-white {
  background-color: white;
}

.w-20 {
  width: 20px;
}
.w-60 {
  width: 60px;
}
