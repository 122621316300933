.auth-qr-code {
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__insturctions {
    padding: 24px;
  }
}
