@import 'src/global-styles/settings/colors';

.FietsKlant {
  position: relative;
  &.Error {
    color: $warning;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .search-select__klant {
    width: 30%;
  }

}
