.OnderhoudRegel {
  table-layout: fixed;
  position: relative;

  td.prijs::before {
    content: "€ ";
  }

  td.prijs, th.prijs {
    text-align: right;
  }

  th.voorraad, td.voorraad {
    text-align: right;
  }

  .mt-5 {
    margin-top: 5px;
  } 
  .five {
    width: 5%;
  }
  .ten {
    width: 10%;
  }
  .fifteen {
    width: 15%;
  }
  .twenty {
    width: 20%;
  }
  .thirty {
    width: 30%;
  }

  .alignRight {
    text-align: right;
  }

  .paddingTop {
    padding-top: 10px;
  }

  .vcenter {
    vertical-align: middle;
  }

  input {
    height: 30px;
    font-size: 16px;
  }

  td.search-select {
    background-color: white;
    width: 30%;
    &__pointer {
      cursor: pointer;
    }
  }

  div.MuiFormControl-root {
    width: 100%;
  }
}
