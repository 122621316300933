@import './../../../global-styles/settings/colors';

.OnderhoudDetail {
  table-layout: fixed;
  position: relative;
  background-color: white;

  .Button {
    margin-left: 20px;
    float: right;
  }

  .cut-text {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 700px;
    white-space: nowrap;
  }

  .inline {
    display: inline-block;
    overflow: hidden;
    padding-left: 10px;
  }

  .five {
    width: 5%;
  }

  .ten {
    width: 10%;
  }

  .fifteen {
    width: 15%;
  }

  .twenty {
    width: 20%;
  }

  .thirty {
    width: 30%;
  }

  .forty {
    width: 40%;
  }

  .fifty {
    width: 50%;
  }

  .alignRight {
    text-align: right;
  }

  .MuiFormControl-root {
    margin: 0;
  }


  .Italic {
    font-style: italic;
  }

  .bg-grey {
    background-color: rgba(0, 0, 0, 0.03);
  }

  .OnClick {
    cursor: pointer;
    vertical-align: middle;
    padding: 0 0 0 10px;

    &:hover {
      background-color: transparentize($primary, 0.8);
    }
  }
  .paddingBottom {
    padding-bottom: 30px;
  }

  .pointer {
    cursor: pointer;
  }

  .datums-verleden, .is-schaderapport {
    font-size: 16px;
  }
  .table-cell-content {
    max-height: 300px;
    overflow: clip;
  }
}
