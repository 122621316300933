.MateriaalOverview {
  .Input {
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
  }
  .toggleOffIcon {
    color: grey;
  }
  .toggleOnIcon {
    color: #1D5AE9;
  }
  .isBestelAdvies {
    color: #1D5AE9;
  }
  .isQr {
    color: #1D5AE9;
  }
}
