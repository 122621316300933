.Experiment {
  .Input {
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
  }

  .auth-qr-code__container {
    margin: 20px auto;
  }

  .margin {
    margin: 30px;
  }

}

.etiketlogo {
  transform: rotate(90deg);
}

.articledesc {
  vertical-align: middle;
}

.eancode {
  text-align: center;
}

.qrcodemargin {
  margin: 0;
}

@media screen
{
  .hideprint, .hideprint *
  {
    display: none !important;
  }
}

