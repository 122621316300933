@import "../../global-styles/settings/colors";

.Button {
  border: none;
  background-color: $primary;
  border-radius: 4px;
  box-sizing: border-box;
  height: 32px;
  font-size: 16px;
  padding: 0 12px;
  line-height: 32px;
  outline: none;
  cursor: pointer;
  color: white;
  text-decoration: none;
  display: inline-block;
  user-select: none;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  &.Active {
    background-color: darken($primary, 10%);
    &:hover {
      background-color: darken($primary, 5%);
    }
    &:active {
      background-color: darken($primary, 15%);
    }
  }
  &:hover {
    background-color: lighten($primary, 5%);
  }
  &:active {
    background-color: darken($primary, 5%);
  }
  &.Warning {
    background-color: $warning;
    &.Active {
      background-color: darken($warning, 10%);
      &:hover {
        background-color: darken($warning, 5%);
      }
      &:active {
        background-color: darken($warning, 15%);
      }
    }
    &:hover {
      background-color: lighten($warning, 5%);
    }
    &:active {
      background-color: darken($warning, 5%);
    }
  }

  &.Success {
    background-color: $success;

    &.Active {
      background-color: darken($success, 10%);

      &:hover {
        background-color: darken($success, 5%);
      }

      &:active {
        background-color: darken($success, 15%);
      }
    }

    &:hover {
      background-color: lighten($success, 5%);
    }

    &:active {
      background-color: darken($success, 5%);
    }
  }

  &.full-width {
    width: 100%;
  }
  &.White,
  &.Accent {
    background-color: white;
    color: black;
    &.Active {
      background-color: rgba(0, 0, 0, 0.15);
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
      &:active {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
    &:active {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  &.Accent {
    line-height: 30px;
    border: 1px solid $border;
  }
  &.White {
    border-radius: 0;
  }
}
