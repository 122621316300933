@import './../../global-styles/settings/colors';

.KlantDetail {
  table-layout: fixed;
  position: relative;

  .Button {
    margin-left: 20px;
    float: right;
  }

  .five {
    width: 5%;
  }

  .ten {
    width: 10%;
  }

  .fifteen {
    width: 15%;
  }

  .twenty {
    width: 20%;
  }

  .thirty {
    width: 30%;
  }

  .forty {
    width: 40%;
  }

  .fifty {
    width: 50%;
  }

  .sixty {
    width: 60%;
  }

  .alignRight {
    text-align: right;
  }

  input#date-picker-inline {
    text-align: right;
  }

  .MuiFormControl-root {
    margin: 0;
  }


  .Italic {
    font-style: italic;
  }

  .EditMode {
    background-color: rgba(52, 107, 235, 0.1);
  }

  input {
    height: 28px;
    font-size: 16px;
  }

  div.MuiFormControl-marginNormal {
    padding-top: 0;
  }

  div.MuiFormControl-root {
    width: 100%;
    padding-top: 0;
  }

  h2 {
    padding: 12px;

    button {
      padding: 0 0 0 6px;
    }
  }

  td.search-select {
    background-color: white;
    width: 30%;
    &__pointer {
      cursor: pointer;
    }
  }
}

.OnClick {
  cursor: pointer;

  &:hover {
    background-color: transparentize($primary, 0.8);
  }
}

button {
  padding: 0;
}


