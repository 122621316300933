@import '../../../global-styles/settings/colors';

.Materiaal {
  position: relative;

  &.Error {
    color: $warning;
  }

  td.prijs::before {
    content: "€ ";
  }


  th.prijs, td.prijs, .alignRight {
    text-align: right;
  }

  .alignLeft {
    text-align: left;
  }

  th.voorraad, td.voorraad, .btw, .alignCenter {
    text-align: center;
  }

  .voorraad > input {
    text-align: center;
  }

  //input.MuiInput-input {
  //  text-align: right;
  //}
  .five {
    width: 5%;
  }

  .seven {
    width: 7%;
  }

  .ten {
    width: 10%;
  }

  .fifteen {
    width: 15%;
  }

  .twenty {
    width: 20%;
  }

  .thirty {
    width: 30%;
  }

  .thirtyfive {
    width: 35%;
  }

  .alignCenter .MuiInputBase-input {
    text-align: center;
  }

  .EditMode {
    background-color: rgba(52, 107, 235, 0.1);
  }
  .mobile {
    font-size: small;
  }
  .detail {
    background-color: rgba(52, 107, 235, 0.1);
    font-size: small;
  }
}
