@import 'src/global-styles/settings/colors';

.KlantFiets {
  position: relative;
  &.Error {
    color: $warning;
  }

  input.MuiInput-input {
    text-align: left;
  }
}
.alignRight {
  text-align: right;
}

a {
  color: black;
  text-decoration: none;
}
.paddingTop {
  padding-top: 10px;
}

