.EanQRPrintCode {
  .Input {
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
  }

  .auth-qr-code__container {
    margin-top: 5px;
  }

  .margin {
    margin: 30px;
  }
}


.etiketlogo {
  transform: rotate(90deg);
}

.articledesc {
  vertical-align: middle;
}


@media screen {
  .hideprint, .hideprint * {
    display: none !important;
  }
}

//td, div {
//  vertical-align: top;
//  line-height: 10px;
//  margin: 0;
//  padding: 0;
//}
table.label {
  //border: 1px solid black;
  table-layout: fixed;
  width: 210px;
  height: 50px;
  margin: 10px 10px 0 10px;
  color: black;
}
table.label td {
  background-color: white;
}

td.qrcode {
  //border: 1px solid black;
  width: 50px;
  height: 50px;
  padding: 0;
}

td.spacer {
  //border: 1px solid black;
  width: 2px;
}

td.articledesc {
  line-height: 10px;
  //border: 1px solid black;
  padding: 0;
  vertical-align: top;
}
td.desc {
  //border: 1px solid black;
  font-size: 12px;
  line-height: 16px;
  vertical-align: middle;
  padding-top: 0;
  padding-bottom: 0;
  height: 40px;
}

span.labelText {
  line-height: 15px;
  font-size: 12px;
  margin-top: 10px;
  padding: 0;
}

div.codeLocation {
}

div.price {
  float: right;
  font-size: 22px;
  margin-top: 20px;
  bottom: 0
}

div.code, div.locatie {
  font-size: 10px;
  line-height: 10px;
  text-align: left;
  margin: 0;
  padding: 0;
}

div.code {
  float: left;
}

div.locatie {
  margin-top: 30px;
  float: left;
}

td.logo {
  width: 30px;
  margin: 0;
  padding: 0;
}

td.logo img {
  height: 50px;
}

table.inner td {
  margin: 0;
  padding: 0;
}
