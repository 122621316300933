.AgendaOverview {
  .Input {
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
    background-color: hsl(155,100%,30%);
  }
  .ten {
    width: 10%;
  }
  .twenty {
    width: 20%;
  }
  .thirty {
    width: 30%;
  }
}
